import {
  Deserializable,
  DeserializeHelper,
} from '../../protocols/deserializable';
import { BaseModel } from './base-model';
import { DatatableData } from '../../protocols/datatable-data';
import { Address } from './address';
import { OrderRequirements } from './order-requirements';
import { Selectable } from '../../protocols/selectable';
import { LocationTax } from './location-tax';
import { DateUtils } from '../../../utils/date-utils';
import { ILocation } from '../../../modules/location';
import { DateTimeFormat } from '../../../modules/date';
import { SmtpDetails } from './smtp-details';
import { Category } from './category-model';
import { PhoneUtils } from 'src/app/utils/phone-utils';
import { SMSConfig } from './sms-config';
import { LocationInsurance } from './location-insurance';
import { AdditionalCharge } from './additional-charge-model';

export class CompanyLocation
  extends BaseModel
  implements DatatableData, Deserializable, Selectable, ILocation {
  public id: number;
  public email: string;
  public companyId: number;
  public company: string;
  public currencyId: number;
  public name: string = null;
  public invoiceCompanyName: string = null;
  public labelSendToEmail:string = null;
  public addressId: string;
  public address: Address;
  public locationSmtp: SmtpDetails;
  public locationSmtpId: number;
  public smtpTypeId: number;
  public locationSMS: SMSConfig;
  public locationSMSId: number;
  public smsTypeId: number;
  public smsServiceProviderTypeId: number;
  public deliveryMinimumAmount: number;
  public deliveryMinimumFrequencyId: number;
  public orderRequirements: OrderRequirements;
  public taxes: LocationTax[];
  public insurance: LocationInsurance[];
  public additionalCharge: AdditionalCharge[];
  public enabled: boolean = true;
  public autoIntuitInvoice: boolean;
  public noDeliveryMinimums: boolean = true;
  public noDriverTeam: boolean = true;
  public teamId: number;
  public deliveryNotes: string = null;
  public phone: string = '';
  public promptToPrintLabelsOnOrderCreate: boolean = true;
  public launchDate: Date = new Date();
  public launchDateString: string = DateUtils.formatDateAndTime(
    this.launchDate,
    DateTimeFormat.YearMonthDay_hyphen
  );
  public showCompanyLogoOnLabels: boolean = true;
  public packageSizingRequired: boolean = false;
  public isEnableRateAndTips: boolean = true;
  public showBrandOnRatAndTip: boolean = true;
  public showPackageDimensionOnLabels: boolean = false;
  public createdBy: string;
  public createdDate: Date = new Date();
  public updatedBy: string;
  public updatedDate: Date = new Date();
  public primaryCategory: Category;

  public onDeserialize() {
    super.onDeserialize();
    this.noDeliveryMinimums = !this.deliveryMinimumFrequencyId;
    this.noDriverTeam = !this.teamId;
    this.address.apartment = this.address.apartment ?? null;
    this.address.notes = this.address.notes ?? null;
    this.deliveryMinimumAmount = this.deliveryMinimumAmount ?? null;
    this.deliveryMinimumFrequencyId = this.deliveryMinimumFrequencyId ?? null;
    this.locationSmtpId = this.locationSmtpId ?? 0;
    this.smtpTypeId = this.smtpTypeId ?? 1,
    this.deliveryNotes = this.deliveryNotes ?? null;
    this.teamId = this.teamId ?? null;
    this.phone = this.phone ?? '';
    this.address = DeserializeHelper.deserializeToInstance(
      Address,
      this.address
    );
    this.primaryCategory = DeserializeHelper.deserializeToInstance(
      Category,
      this.primaryCategory
    );
    this.orderRequirements = DeserializeHelper.deserializeToInstance(
      OrderRequirements,
      this.orderRequirements
    );
    this.taxes = DeserializeHelper.deserializeArray(LocationTax, this.taxes);
    this.launchDate =
      DeserializeHelper.deserializeToDate(this.launchDate) ?? null;
    this.launchDateString =
      DateUtils.formatDateAndTime(
        this.launchDate,
        DateTimeFormat.YearMonthDay_hyphen
      ) ?? null;
    this.insurance = DeserializeHelper.deserializeArray(LocationInsurance, this.insurance) ?? null;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  get formattedPhoneNumber(): string {
    if (!this.phone) {
      return null;
    }
    return PhoneUtils.formatPhoneNumber(this.phone);
  }
}
